import fancybox from '@fancyapps/fancybox';



function show() {
    $('.column').eq(1).each(function () {
        $(this).animate({opacity: 1}, 800, function () {
        });
    });
}

function show2() {
    $('.column').eq(2).each(function () {
        $(this).animate({opacity: 1}, 800, function () {
        });
    });
}

function show3() {
    $('.column').eq(0).each(function () {
        $(this).animate({opacity: 1}, 800, function () {
        });
    });
}

function setHeight() {
    var h = $('#content').outerHeight(true);
    $('.build-div').height(h + 200);
    $('.build-content').height(h + 145);
}


function setInputActive(el) {
    let getEl = $(el);
    getEl.addClass("is-val");
    getEl.next('.input-label').addClass("active");
}

function inputInit() {
    $('input, textarea, select').each(function () {
        if ($(this).val() != '') {
            setInputActive(this);
        }
    });

    $('.input-label').click(function () {
        let prev = $(this).prev();
        prev.click().focus();
    });

    $('.input-label').mouseover(function () {
        let prev = $(this).prev();
        if (prev.is('select')) {
            prev.click().focus();
        }
    });

    $('input, select, textarea').focus(function () {
        setInputActive(this);
    });

    $('input, select, textarea').change(function () {
        setInputActive(this);
    });

    $('input, select, textarea').blur(function () {
        if ($(this).val() === '') {
            $(this).removeClass("is-val");
            $(this).next('.input-label').removeClass("active");
        }
    });
}

window.setHeight = setHeight;
window.inputInit = inputInit;

$(function () {

    inputInit();
   /* $('.column').css('opacity', '0.01');*/
    /*setHeight();

    setTimeout(setHeight, 3000);

    $(window).resize(function () {
        setHeight();
    });*/

    $('.print').click(function () {
        window.print();
        return false;
    })

    $('#header-menu').click(function () {
        var mainMenu = $('#mainmenuCont');
        if (mainMenu.css('display') === 'none') {
            $('.menu_button').addClass('active');
            //$('.header-menu-line').toggleClass('active');
            mainMenu.delay(300).slideToggle(300);
        } else {
            mainMenu.slideToggle(300);
            //setTimeout(function () {
            $('.menu_button').removeClass('active');
            //$('.header-menu-line').toggleClass('active');
            //}, 300);
        }
        return false;
    });

    function offClick(e) {
        return false;
    }

    function checkMenu() {
        if ($( window ).width() < 750) {
            $('#mainmenuCont > ul > li > a').each(function () {
                $(this).on('click', offClick);
            })
        } else {
            $('#mainmenuCont > ul > li > a').each(function () {
                $(this).off('click', offClick);
            })
        }
    }

    $( window ).resize(function() {
        checkMenu();
    });

    checkMenu();

});